import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  constructor(
    private db:AngularFireDatabase
  ) { }

  getAllPayments(loc, printer){
    if(printer == 'alle'){
      return this.db.list('projects/'+loc+'/abschluss/');
    }else{
      //console.log('projects/'+loc+'/Payments/verkauft/'+printer);
      return this.db.list('projects/'+loc+'/Payments/');
    }

  }

  getVerkaufszahlen(loc){
   return this.db.list('projects/'+loc+'/verkaufszahlen');
  }


  getUserPayments(loc){
    return this.db.list('projects/'+loc+'/Payments/user/');

  }

  getAllPrinters(loc){
    return new Promise((resolve,reject)=>{
      let printers = [];
      //console.log('aufruf');
      let x = this.db.list('projects/'+loc+'/Payments/verkauft');
      x.snapshotChanges().subscribe((res)=>{
        printers= [];
        let x = 0;
        // //console.log(res.);
        res.forEach((item)=>{
          x++;
          printers.push(item.key);
          if(x == res.length){
            resolve(printers);
          }
          // console.log(item.key);
        });
        if(res.length == 0){
          resolve(true);
        }
        // console.log(printers);
      })
    })
  }
  getPrinterData(loc){
      return this.db.list('projects/'+loc+'/printer');
  }

  getKellnerumsätze(loc){
    return this.db.list('projects/'+loc+'/Payments/user');
  }
  getKellnerUmsaetzeAbgerechnet(loc){
    return this.db.list('projects/'+loc+'/Payments/abgerechnet/user');
  }

  getKellnerName(loc){
    return this.db.list('location/'+loc+'/user');
  }

  getMyProjects(){
    let user = JSON.parse(localStorage.getItem('user'));
    user = user.uid
    if(user == '9EDjLD7YHPPmTdGe3hXd8cpBlxh1'){
      return this.db.list('/location');
    }else{
      return this.db.list('/user/'+user+'/location')
    }
  }

  getMyUsers(loc){
    // let loc = (JSON.parse(localStorage.getItem('location'))).key;
    //console.log(loc);
    return this.db.list('projects/'+loc+'/user')
  }

  getAllMyBons(loc){
    return this.db.list('projects/'+loc+'/bon/nummer');
  }

  lockKellner(status,uid,loc){
    let state = '';
    if(status == 'true') state = 'false';
    if(status == 'false') state = 'true';
    return this.db.object('projects/'+loc+'/user/'+uid).update({active: state})
  }

  deleteKeller(uid,loc){
    this.db.object('projects/'+loc+'/user/'+uid).remove();
  }

  addKellner(value,loc){
    //console.log(value);
    this.db.list('location/'+loc+'/user').update(value.token,{name:value.name,vorname:value.vorname,active:'false',uid:value.token});
    this.db.list('projects/'+loc+'/user').update(value.token,{name:value.name,vorname:value.vorname,active:'false',uid:value.token});
  }

  getProducts(loc){
    return this.db.list('projects/'+loc+'/products')
  }
  getOptions(loc){
    return this.db.list('projects/'+loc+'/param/attachments')

  }

  saveWholeProds(loc,prod){
    this.db.object('projects/'+loc+'/products/').set(prod);
  }
  updateProducts(loc,array:any[],group:any[]){
    let pfad = '/';
    group.forEach(item=>{
      pfad = pfad+item+'/';

    })
    console.log(array,group,pfad);
    console.log('projects/'+loc+'/products'+pfad);
    this.db.object('projects/'+loc+'/products'+pfad).remove();
    array.forEach(item=>{
      console.log(item);
      if(item.token == undefined || (item.token != undefined && item.name == undefined)){
        console.log('projects/'+loc+'/products'+pfad,item);
        this.db.object('projects/'+loc+'/products'+pfad).update(item);
      }else{
        console.log('projects/'+loc+'/products'+pfad+item.token,item);
        this.db.object('projects/'+loc+'/products'+pfad+item.token).set(item);
      }
    })
  }

  removeProducts(loc,token,path){
    let pfad = '/';
    path.forEach(item=>{
      pfad = pfad+item+'/';

    })
    console.log(path,pfad,token);
    this.db.list('projects/'+loc+'/products/'+pfad).remove(token).then(()=>{
    });
  }

  updateSingleProduct(loc,prod,group){
    console.log(group);
    let pfad = '/';
    group.forEach(item=>{
      pfad = pfad+item+'/';
      })
    // console.log(('projects/'+loc+'/products/'+pfad+prod.token));
    return this.db.object('projects/'+loc+'/products/'+pfad+prod.token).set(prod);
  }

  deleteComponent(loc,token,group:any[]){
    let pfad = '/';
    group.forEach(item=>{
    pfad = pfad+item+'/';
    })
    return this.db.object('projects/'+loc+'/products'+pfad+token).remove();
  }

  updateCat(loc,data){
    return this.db.object('projects/'+loc+'/products/'+data.index).update(data);
  }

  setOption(value,loc){
    return this.db.object('projects/'+loc+'/param/attachments/'+value.token).set({name:value.name,preis:value.preis});

  }

}
