<div class="container">
  <h3>{{locName == '' ? 'Veranstaltung auswählen:' : 'Ausgewählte Veranstaltung: '+locName}}</h3>
  <p class="d-inline-flex gap-1 pt-3">
    <a class="btn btn-success" data-bs-toggle="collapse">
      Aktive Veranstaltung:
    </a>
  </p>  <div class="row">
    <div class="col-12 col-lg-6 col-xl-3" col-lg-2 *ngFor="let loc of myLocations">
      <div class="card m-0 mb-1" [ngClass]="this.selected == loc.key ? 'bg-warning':''">
        <div class="card-body p-2" style="font-size: 15px;"  (click)="onChange(loc)" aria-label="Default select example">
          {{loc.name}}
        </div>
      </div>
    </div>
  </div>
  <p class="d-inline-flex gap-1 pt-3">
    <a class="btn btn-outline-secondary" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
      Vergangene Veranstaltung
    </a>
  </p>
  <div class="collapse" id="collapseExample">
    <div class="card card-body">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3" col-lg-2 *ngFor="let loc of pastLocations">
          <div class="card m-0 mb-1" [ngClass]="this.selected == loc.key ? 'bg-warning':''">
            <div class="card-body p-2" style="font-size: 15px;"  (click)="onChange(loc)" aria-label="Default select example">
              {{loc.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
