<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed':''">
  <div class="logo-container">
    <button class="logo"(click)="toggleCollapse()">
      <img src="../../assets/img/ico/nurLogo.png">
    </button>
    <!-- <div class="logo-text" *ngIf="collapsed">OrderExpress</div> -->
    <!-- <button class="xerl" *ngIf="collapsed" (click)="closeSidenav()">
      <i class="fal fa-times close-icon"></i>
    </button> -->
  </div>
  <ul class="sidenav-nav" [hidden]="collapsed">
    <div>
      <li (click)="collapsess()" class="sidenav-nav-item"  *ngFor="let data of navData">
        <a type="button" href="" class="sidenav-nav-link"
          [routerLink]="[data.routerLink]"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:true}"
        >
          <i class="sidenav-link-icon" [class]="data.icon"></i>
          <span class="sidenav-link-text" *ngIf="collapsed">{{data.label}}</span>
        </a>
      </li>
    </div>
    <div class="veranst">{{locName}}
      <ul class="sidenav-nav" hidden='false'>
        <li class="sidenav-link-item"(click)="authS.SignOut()">
          <i class="sidenav-link-icon bi bi-box-arrow-left"></i>
          <span class="sidenav-link-text">Logout</span>
        </li>
      </ul>
    </div>

  </ul>

</div>
